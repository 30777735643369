<template>
  <div ref="flyingBirds" @click="$emit('click')" class="flying-birds" v-bind:class="{ 'flip': isFlip }">
      <div ref="flyingBird1" class="flying-bird"><img :src="require('@/assets/images/q1-intro/bird-fly-1.png')"/></div>
      <div ref="flyingBird2" class="flying-bird"><img :src="require('@/assets/images/q1-intro/bird-fly-2.png')"/></div>
      <div ref="flyingBird3" class="flying-bird"><img :src="require('@/assets/images/q1-intro/bird-fly-3.png')"/></div>
    </div>
</template>

<script>
import {gsap} from 'gsap';
export default {
  name: 'Frame',
  props: ['duration','flip','loop'],
  emits: ['click'],
  data() {
    return {
      isFlip: null
    }
  },
  mounted() {
    this.isFlip = this.flip
    this.start()
    this.move()
  },
  methods: {
    start() {
      const { flyingBird1,flyingBird2,flyingBird3 } = this.$refs
      this.tl_flying_bird.to(flyingBird1,{ opacity:0,duration: 0.5,delay: 1 })
      this.tl_flying_bird.from(flyingBird2,{ opacity:0,duration: 0.2 },"-=0.4")
      this.tl_flying_bird.to(flyingBird2,{ opacity:0,duration: 0.2 })
      this.tl_flying_bird.from(flyingBird3,{ opacity:0,duration: 0.5 },"-=0.4")
    },
    move() {
      const {flyingBirds} = this.$refs
      if(this.isFlip) {
        this.tl_flying.fromTo(flyingBirds,{left:'10vw',top:'30vh'},{left:'90vw',top:'10vh',duration: this.duration})
        this.$forceUpdate()
      } else {
        this.tl_flying.to(flyingBirds,{left:'10vw',top:'30vh',duration: this.duration})
        this.$forceUpdate()
      }
      if(this.loop) {
        this.tl_flying.eventCallback("onComplete", ()=>{
          this.isFlip = !this.isFlip
          this.$forceUpdate()
          this.move()
        });
      }
    }
  },
  computed: {
    tl_flying_bird() {
      return gsap.timeline({repeat: -1, yoyo: true, repeatDelay: 1});
    },
    tl_flying (){
      return gsap.timeline({repeat: 0, yoyo: false});
    }
  },
  beforeUnmount() {
    this.tl_flying_bird.pause()
    this.tl_flying.pause()
  },
}
</script>

<style scoped lang="scss">
.flying-birds {
  position: absolute;
  width: 200px;
  height: 200px;
  left: 100vw;
  top: 10vh;
  cursor: pointer;
  .flying-bird {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    img {
      width: 100%;
    }
  }
  &.flip {
    left: 10vw;
    top: 20vh;
    .flying-bird {
      transform: scaleX(-1) rotate(25deg);
    }
  }
}
</style>
