<template>
  <div id="frame" ref="backgroundFrame">
    <flying-bird :duration="20" :flip="true"/>
    <div id="about-content">
      <p>
        Art, I’m in เป็นโครงการระดมทุนผ่าน “ช็อป” และ “ช่วย” บนแพลตฟอร์ม Socialgiver มอบให้องค์กร Art for Cancer by Ireal เพื่อจัดส่ง “ชุดศิลปะดูแลใจ” ให้กับผู้ป่วยมะเร็งที่สนใจใช้กระบวนการศิลปะบำบัดในการดูแลจิตใจควบคู่กับการรักษากาย จำนวน 50 ท่าน เพื่อให้ได้รับการโอบอุ้มอารมณ์ความรู้สึก เพิ่มมุมมองใหม่ในการรับมือกับมะเร็งและใช้ชีวิตต่อไปได้อย่างสมดุล
      </p>
      <p>
        ร่วมเป็นส่วนหนึ่งในการส่งต่อสุขภาพใจได้แล้ว ที่ www.socialgiver.com หรือดาวน์โหลดแอปฯ Socialgiver
      </p>
      <button ref="nextBtn" class="button-text" type="button" @click="next">กลับไปหน้าแรก &#xbb;</button>
    </div>
  </div>
</template>

<script>
import FlyingBird from '@/components/FlyingBird';
import {gsap} from 'gsap';
//gsap.globalTimeline.timeScale(4)
export default {
  name: 'End',
  components: {
    FlyingBird
  },
  data() {
  },
  mounted() {
    this.$store.dispatch('app/stopMusic')
  },
  methods: {
    next() {
      this.$store.dispatch('app/clearAnswer')
      this.$router.push('/')
    },
    seek() {
      this.tl_enter.seek(this.tl_enter.nextLabel())
    }
  },
  beforeUnmount() {
    this.tl_enter.pause()
  },
  computed: {
    tl_enter() {
      return gsap.timeline({delay:3});
    }
  }
}
</script>

<style lang="scss" scoped>
#frame {
  width: 100%;
  height: 100%;

  background: no-repeat url('~@/assets/images/white-paper.jpg') top center;
  background-size: cover;
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  line-height: 1.7em;

  #about-content {
    padding: 20px 5%;
  }
}
</style>

