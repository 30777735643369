<template>
  <div id="choose-frame" ref="backgroundFrame">
    <div id="choose-image">
      <div ref="frame-image">
        <img ref="paper" :src="require('@/assets/images/q8-choosetime/frame/paper.png')"/>
        <transition name="fade">
          <img ref="window" :src="require('@/assets/images/q8-choosetime/frame/window.png')" v-if="time===null"/>
          <img ref="window" :src="require('@/assets/images/q8-choosetime/frame/frame-morning.png')" v-else-if="time==='morning'"/>
          <img ref="window" :src="require('@/assets/images/q8-choosetime/frame/frame-day.png')" v-else-if="time==='day'"/>
          <img ref="window" :src="require('@/assets/images/q8-choosetime/frame/frame-evening.png')" v-else-if="time==='evening'"/>
          <img ref="window" :src="require('@/assets/images/q8-choosetime/frame/frame-night.png')" v-else-if="time==='night'"/>
        </transition>
      </div>
    </div>
    <div id="choose-form" ref="introMessages">
      <transition name="slidefade">
        <div  class="intro-messages" v-show="time===null">
          ที่เราเจอกันตอนนี้ เป็นช่วงไหนของวันนะ
          <div class="multi-buttons" ref="q2Buttons">
            <button class="button block button-morning" type="button" @click="selectTime('morning')"></button>
            <button class="button block button-day" type="button" @click="selectTime('day')"></button>
            <button class="button block button-evening" type="button" @click="selectTime('evening')"></button>
            <button class="button block button-night" type="button" @click="selectTime('night')"></button>
          </div>
        </div>
      </transition>

      <transition name="slidefade">
        <div class="intro-messages" v-show="time!==null">
          <div class="time-messages" ref="timeMessages">
            <div ref="timeMessage1">เธอเห็นแสงที่ลอดออกมาจากริมหน้าต่างมั้ย</div>
            <div ref="timeMessage2">สวยจัง</div>
          </div>
          
          <div ref="nextButtons" class="next-buttons">
            <button class="button-text" type="button" @click="next">ไปต่อ &#xbb;</button>
            <br/>
            <button class="button-text button-text-sm" type="button" @click="clear">เลือกใหม่</button>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>

import {gsap} from 'gsap';
//gsap.globalTimeline.timeScale(4)
export default {
  name: 'choosetime',
  components: {
  },
  data() {
  },
  mounted() {
    //this.$store.dispatch('app/stopMusic')
    const {introMessages,q2Buttons} = this.$refs
    this.tl_enter.from(introMessages,{duration: 2,opacity:0,top: 20,delay:1.5})
    this.tl_enter.from(q2Buttons,{duration: 1.5,delay:0,opacity:0,top: 20})
  },
  methods: {
    next() {
      this.$router.replace('/choose-mood')
    },
    selectTime(time) {
      this.$store.dispatch('app/setMusic',require('@/assets/audio/fx-'+time+'.mp3'))
      this.$store.dispatch('app/setAnswer',{handle:"time",value:time})
      //this.next()

      const {timeMessage1,timeMessage2,nextButtons} = this.$refs
      this.tl_enter.from(timeMessage1,{duration: 2,opacity:0,top: 10,delay:1})
      this.tl_enter.from(timeMessage2,{duration: 2,opacity:0,top: 10})
      this.tl_enter.to(nextButtons,{duration: 2,opacity:1,top: 0})
    },
    clear() {
      this.$store.dispatch('app/setAnswer',{handle:"time",value:null})
      //this.$store.dispatch('app/stopMusic')
    }
  },
  beforeUnmount() {
    this.tl_enter.pause()
  },
  computed: {
    tl_enter() {
      return gsap.timeline()
    },
    time() {
      return this.$store.state.app.answers.time
    }
  }
}
</script>

<style lang="scss" scoped>
#choose-frame {
  width: 100%;
  height: 100%;

  background: no-repeat url('~@/assets/images/white-paper.jpg') top center;
  background-size: cover;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: top;
  align-content: center;
  text-align: center;
  line-height: 1.7em;
  flex-direction: column;
  padding-top: 40px;
  
  @media only screen and (max-width: 500px) {
    font-size: 0.93em;
    padding-top: 10px;
  }
  #choose-image {
    width: 450px;
    height: 450px;
    position: relative;
    margin: 0 0 20px 0;
    img {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    @media only screen and (max-width: 500px) {
      margin: 0 0 10px;
      width: 90vw;
      height: 90vw;
    }
  }
  #choose-image-1 {
    opacity: 0;
  }
  #choose-image-2 {
    opacity: 0;
  }

  .button-text {
    margin: 40px 0 0 0;
  }

  #choose-form {
    position: relative;
    width: 100%;
    top: 0;
  }
  .intro-messages {
    margin: 0;
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .multi-buttons {
    width: 500px;
    position: relative;
    opacity: 1;
    top: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    padding: 5px 0 0 0;

    @media only screen and (max-width: 500px) {
      width: 200px;
      gap: 0;
      grid-template-columns: 1fr;
    }

    button.button {
      margin: 0;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      text-shadow: 1px 1px 2px rgba(0,0,0,0.4);
      font-size: 1.1em;
      line-height: 2em;
      padding: 10px;
      border-color: transparent;
      height: 60px;
    }
    .button-morning {
      background-image: url('~@/assets/images/q8-choosetime/btn-morning.png');
      color: #000;
    }
    .button-day {
      background-image: url('~@/assets/images/q8-choosetime/btn-day.png');
      color: #fff;
    }
    .button-evening {
      background-image: url('~@/assets/images/q8-choosetime/btn-evening.png');
      color: #000;
    }
    .button-night {
      background-image: url('~@/assets/images/q8-choosetime/btn-night.png');
      color: #fff;
    }
  }
  .time-messages {
    p {
      opacity: 0;
      top: 20px;
      position: relative;
    }
  }
  .next-buttons {
      opacity: 0;
      top: 20px;
      position: relative;
  }
}
</style>

