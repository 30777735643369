<template>
  <div id="choose-frame" ref="backgroundFrame">
    <div id="choose-image">
      <div ref="frame-image">
        <frame :hand="true"/>
      </div>
    </div>
    <div id="choose-form" ref="introMessages">
      <transition name="slidefade">
        <div class="intro-messages" v-if="mood===null">
          <p>อากาศรอบตัวเธอตอนนี้ ทำให้เธอรู้สึกยังไงบ้าง</p>
          <div class="multi-buttons" ref="q2Buttons">
            <button class="button block button-morning" type="button" @click="selectMood('warm')">อุ่น ร้อน</button>
            <button class="button block button-day" type="button" @click="selectMood('cool')">หนาว เย็น</button>
            <button class="button block button-evening" type="button" @click="selectMood('normal')">เฉยๆ</button>
          </div>
        </div>
        <div class="intro-messages" v-else>
          <button ref="nextBtn" class="button-text" type="button" @click="next">ไปต่อ &#xbb;</button>
          <br/>
          <button ref="nextBtn" class="button-text button-text-sm" type="button" @click="clear">เลือกใหม่</button>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>

import Frame from '@/components/Frame';
import {gsap} from 'gsap';
export default {
  name: 'choosemood',
  components: {
    Frame
  },
  data() {
  },
  mounted() {
    if(this.$store.state.app.answers.time === null) {
      window.location = '/'
    }

    const {introMessages,q2Buttons} = this.$refs
    this.tl_enter.from(introMessages,{duration: 2,opacity:0,top: 20})
    this.tl_enter.from(q2Buttons,{duration: 1.5,delay:0,opacity:0,top: 20})
  },
  methods: {
    next() {
      this.$router.replace('/rest')
    },
    selectMood(mood) {
      this.$store.dispatch('app/setAnswer',{handle:"mood",value:mood})
    },
    clear() {
      this.$store.dispatch('app/setAnswer',{handle:"mood",value:null})
    }
  },
  beforeUnmount() {
    this.tl_enter.pause()
  },
  computed: {
    tl_enter() {
      return gsap.timeline();
    },
    mood() {
      return this.$store.state.app.answers.mood
    }
  }
}
</script>

<style lang="scss" scoped>
#choose-frame {
  width: 100%;
  height: 100%;

  background: no-repeat url('~@/assets/images/white-paper.jpg') top center;
  background-size: cover;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: top;
  align-content: center;
  text-align: center;
  line-height: 1.7em;
  flex-direction: column;
  padding-top: 40px;
  
  @media only screen and (max-width: 500px) {
    font-size: 0.93em;
    padding-top: 10px;
  }
  #choose-image {
    width: 450px;
    height: 450px;
    position: relative;
    margin: 0 0 20px 0;
    img {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    @media only screen and (max-width: 500px) {
      margin: 0 0 10px;
      width: 90vw;
      height: 90vw;
    }
  }
  #choose-image-1 {
    opacity: 0;
  }
  #choose-image-2 {
    opacity: 0;
  }

  .button-text {
    margin: 0;
  }

  #choose-form {
    position: relative;
    width: 100%;
    top: 20px;
  }
  .intro-messages {
    margin: 0;
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .multi-buttons {
    width: 500px;
    position: relative;
    opacity: 1;
    top: 0;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 10px;
    padding: 1em 0 0 0;

    @media only screen and (max-width: 500px) {
      width: 220px;
      gap: 10px;
      grid-template-columns: 1fr;
    }
  }
}
</style>

