<template>
<div>
  <div id="ani-frame" ref="backgroundFrame">
    <flying-bird :duration="duration" @click="next" :flip="true" :loop="false"/>
  </div>
</div>
</template>

<script>
import FlyingBird from '@/components/FlyingBird';
import {gsap} from 'gsap';
export default {
  name: 'Outtro',
  components: {
    FlyingBird
  },
  data() {
    return {
      duration: 20
    }
  },
  mounted() {
    const { backgroundFrame } = this.$refs
    this.tl_intro_scene.to(backgroundFrame,{backgroundSize:'auto 100%',duration: this.duration})
    this.tl_intro_scene.eventCallback("onComplete", ()=>{
      this.next()
    });

    this.$store.dispatch('app/setMusic',require('@/assets/audio/intro-1.mp3'))
  },
  beforeUnmount() {
    this.tl_intro_scene.pause()
  },
  methods: {
    next() {
      this.$router.push('/')
    },
  },
  computed: {
    tl_intro_scene (){
      return gsap.timeline({repeat: 0, yoyo: false});
    }
  }
}
</script>

<style lang="scss" scoped>
#ani-frame {
  width: 100%;
  height: 100%;
  background: no-repeat url('~@/assets/images/q1-intro/outside-view.jpg') center center;
  background-size: auto 120%;
  position: absolute;
  overflow: hidden;
}
</style>

