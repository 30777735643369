<template>
  <div id="choose-frame" ref="backgroundFrame">
    <div id="choose-image">
      <div ref="frame-image">
        <frame :downloadable="true" :animation="true" class="download-frame">
          <span>ขอบคุณที่มาสร้างงานศิลปะด้วยกัน</span> <span>ลองมองภาพของเธอสักครู่</span><br/>
          <span>มันอาจมีความหมายของคำตอบอยู่ก็ได้นะ</span><br/>
          <span>แชร์ภาพงานของเธอออกไป</span> <span>และชวนเพื่อนๆ มาสร้างงานศิลปะกัน</span><br/>
          <span>ให้งานศิลปะ ได้ปลดปล่อยจินตนาการที่เป็นเธอ</span>
        </frame>
      </div>
      <a href="https://ddl.socialgiver.com/ddl/Art-for-Cancer" class="button block" target="_blank"><span> คลิกเพื่อบริจาคชุดศิลปะดูแลใจ</span></a>
    </div>
    <div id="choose-form">
      <transition name="slidefade">
        <div  class="intro-messages">
          <div>
            <p><img :src="require('@/assets/images/logos/artimin.png')" width="100" /></p>
            <p>
              <span>Art, I’m in เป็นโครงการระดมทุนผ่าน “ช็อป” และ “ช่วย”</span> <span>บนแพลตฟอร์ม <a href="https://ddl.socialgiver.com/ddl/Art-for-Cancer" target="_blank">Socialgiver</a></span>  <span>มอบให้องค์กร <a href="https://artforcancerbyireal.com/" target="_blank">Art for Cancer by Ireal</a> เพื่อจัดส่ง “<a href="https://ddl.socialgiver.com/ddl/Art-for-Cancer" target="_blank">ชุดศิลปะดูแลใจ</a>”</span> <span>ให้กับผู้ป่วยมะเร็งที่สนใจใช้กระบวนการศิลปะบำบัด</span> <span>ในการดูแลจิตใจควบคู่กับการรักษากาย</span> <span>จำนวน 50 ท่าน เพื่อให้ได้รับการโอบอุ้มอารมณ์ความรู้สึก</span> <span>เพิ่มมุมมองใหม่ในการรับมือกับมะเร็งและใช้ชีวิตต่อไปได้อย่างสมดุล</span>
            </p>
            <p>
              <a href="https://ddl.socialgiver.com/ddl/Art-for-Cancer" class="button big" target="_blank"><span>ร่วมเป็นส่วนหนึ่งกับเราที่</span> <span> www.socialgiver.com</span></a>
              <br/>
              <br/>
              <span>หรือดาวน์โหลดแอปฯ Socialgiver</span>
            </p>
            <p class="v-center">
              <a href="https://ddl.socialgiver.com/ddl/Art-for-Cancer" target="_blank"><img :src="require('@/assets/images/logos/socialgiver.png')" width="60" /></a> x 
              <a href="https://artforcancerbyireal.com/" target="_blank"><img :src="require('@/assets/images/logos/artforcancer.png')" width="55" /></a>
            </p>
            <p>Special Thanks</p>
            <p class="v-center">
              <a href="https://www.medensy.com" target="_blank"><img :src="require('@/assets/images/logos/medensy.png')" width="130" /></a> 
              <a href="https://www.facebook.com/ArtbyHeartBangkok" target="_blank"><img :src="require('@/assets/images/logos/artbyheart.png')" width="70" /></a>
            </p>
            <br/>
            <button ref="nextBtn" class="button-text" type="button" @click="next">กลับไปหน้าแรก &#xbb;</button>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Frame from '@/components/Frame';
import {gsap} from 'gsap';
//gsap.globalTimeline.timeScale(4)
export default {
  name: 'result',
  components: {
    Frame
  },
  data() {
  },
  mounted() {
    /*
    setTimeout(()=>{
      if( this.$store.state.app.answers.time === null || 
          this.$store.state.app.answers.mood === null || 
          this.$store.state.app.answers.ground === null || 
          this.$store.state.app.answers.view === null || 
          this.$store.state.app.answers.man === null) {
        window.location = '/'
      }
    },300)
    */

    this.$store.dispatch('app/setMusic',require('@/assets/audio/fx-'+this.time+'.mp3'))

    const {introMessages} = this.$refs
    this.tl_enter.from(introMessages,{duration: 2,opacity:0,top: 20,delay:2})

  },
  methods: {
    next() {
      this.$store.dispatch('app/clearAnswer')
      window.location = '/'
    }
  },
  beforeUnmount() {
    this.tl_enter.pause()
  },
  computed: {
    tl_enter() {
      return gsap.timeline();
    },
    time() {
      return this.$store.state.app.answers.time
    }
  }
}
</script>

<style lang="scss" scoped>
#choose-frame {
  width: 100%;
  height: 100%;

  background: no-repeat url('~@/assets/images/white-paper.jpg') top center;
  background-size: cover;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: top;
  align-content: center;
  text-align: center;
  line-height: 1.7em;
  flex-direction: column;
  padding-top: 40px;
  
  @media only screen and (max-width: 500px) {
    font-size: 0.93em;
    padding-top: 10px;
  }
  #choose-image {
    width: 450px;
    position: relative;
    margin: 0 0 20px 0;
    img {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    @media only screen and (max-width: 500px) {
      margin: 0 0 10px;
      width: 90vw;
    }
  }
  #choose-image-1 {
    opacity: 0;
  }
  #choose-image-2 {
    opacity: 0;
  }

  .button-text {
    margin: 0;
  }

  #choose-form {
    position: relative;
    width: 100%;
  }
  .intro-messages {
    font-family: "WF Thai Sans", sans-serif;
    font-size: 0.8em;
    line-height: 1.6em;
    color: #000;
    background: #fff;
    margin: 0;
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    .intro-message {
      position: relative;
    }
    &>div {
      padding: 40px 0;
      margin: 0 auto;
      max-width: 1000px;
      p span {
        white-space:nowrap;
      }
    }
    a {
      color: #853B92;
      font-weight: bold !important;
    }
  }
  .multi-buttons {
    max-width: 800px;
    position: relative;
    opacity: 1;
    top: 0;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 0;
    padding: 10px 2em;

    img {
      max-width: 100%;
    }
    button {
      padding: 0;
      margin: 0;
    }

    @media only screen and (max-width: 500px) {
      width: 100%;
      gap: 10px;
      grid-template-columns: repeat(2,1fr);
    }
  }
}
.download-frame{

  span {
    white-space: nowrap;
  }
}
</style>

