<template>

  <div id="rest-frame" ref="backgroundFrame" @click="nextLabel">
    <div id="rest-backdrop" ref="backgroundBackdrop"></div>
    <div>
      <div id="rest-image" ref="restImage">
        <img id="rest-image-1" ref="restImage1" :src="require('@/assets/images/q12-rest/man-rest.png')"/>
        <div id="rest-image-2" ref="restImage2">
          <div id="rest-image-frame">
            <frame />
          </div>
          <img :src="require('@/assets/images/q12-rest/man-bird-frame.png')"/>
        </div>
      </div>
      <div id="messages">
        <div id="intro-messages" ref="introMessages">
          <span ref="introMessage1">พักสายตาซักครู่นะ</span>
          <br/>
          <span ref="introMessage2">แล้วหายใจเข้าออกสบายๆ</span>
        </div>

        <div id="rest-messages" ref="restMessages">
          <span ref="restMessage1">ข้างนอกนั่น เธอมองเห็นอะไรบ้างเหรอ</span>
          <br/>
          <span ref="restMessage2">เรามาลองวาดไปพร้อมกันนะ</span>
          <br/>
          <button ref="nextBtn" class="button-text" type="button" @click="next">ไปต่อ &#xbb;</button>
        </div>
      </div>
    </div>
    <audio ref="speakAudio" :src="require('@/assets/audio/rest-speak.mp3')" preload mute></audio>
  </div>
</template>

<script>

import {gsap} from 'gsap';
import Frame from '@/components/Frame';
//gsap.globalTimeline.timeScale(4)
export default {
  name: 'Rest',
  components: {
    Frame
  },
  data() {
  },
  mounted() {

    if(this.$store.state.app.answers.time === null || this.$store.state.app.answers.mood === null) {
      window.location = '/'
    }

    const {restImage1,restImage2} = this.$refs
    const {introMessages,introMessage1,introMessage2} = this.$refs
    const {restMessage1,restMessage2,nextBtn} = this.$refs
    // window + bird
    this.tl_enter.to(restImage1,{duration: 2,opacity:1,onComplete:()=>{
      let speakAudio = this.$refs.speakAudio
      speakAudio.volume = 1
      speakAudio.play()
    }})
    // intro msg
    this.tl_enter.from(introMessage1,{duration: 1.5,delay:0,opacity:0,y: 20},'-=2')
    this.tl_enter.from(introMessage2,{duration: 1.5,delay:2,opacity:0,y: 20})
    this.tl_enter.to(restImage2,{duration: 3,opacity:1,delay:3},'restImage')
    this.tl_enter.to(introMessages,{duration: 2,opacity:0},'-=2')
    this.tl_enter.to(restImage1,{duration: 3,opacity:0},'-=2')
  
    this.tl_enter.from(restMessage1,{duration: 1.5,delay:0,opacity:0,y: 20})
    this.tl_enter.from(restMessage2,{duration: 1.5,delay:1.5,opacity:0,y: 20},'restImageDone')
    this.tl_enter.from(nextBtn,{duration: 1.5,delay:2,opacity:0,y: 20},'ended')
  },
  methods: {
    next() {
      this.$router.replace('/choose-ground')
    },
    nextLabel() {
      this.tl_enter.seek(this.tl_enter.nextLabel())
      //console.log(this.tl_enter.nextLabel())
    }
  },
  beforeUnmount() {
    this.tl_enter.pause()
  },
  computed: {
    tl_enter() {
      return gsap.timeline();
    }
  }
}
</script>

<style lang="scss" scoped>
#rest-backdrop {
  background: #222;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}
#rest-frame {
  width: 100%;
  height: 100%;

  background: no-repeat url('~@/assets/images/white-paper.jpg') top center;
  background-size: cover;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
  line-height: 1.7em;

  @media only screen and (max-width: 500px) {
    font-size: 0.93em;
  }
  #rest-image {
    width: 500px;
    height: 520px;
    position: relative;
    img {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    @media only screen and (max-width: 500px) {
      width: 90vw;
      height: 95vw;
      padding: 0;
      margin: 0 auto;
    }
  }
  #rest-image-1 {
    opacity: 0;
  }
  #rest-image-2 {
    opacity: 0;
  }
  #rest-image-3 {
    opacity: 0;
  }
  #rest-image-4 {
    opacity: 0;
  }
  #rest-image-frame {
    mask-image: url('~@/assets/images/q12-rest/window-mask.png');
    mask-size: 100%;
  }

  .button-text {
    margin: 40px 0 0 0;
  }

  #messages {
    position: relative;
    #intro-messages {
      margin: 0;
      span {
        display: inline-block;
        position: relative;
      }
    }

    #rest-messages {
      position: absolute;
      width: 100%;
      text-align:center;
      top: 0;
      margin: 0;
      span {
        display: inline-block;
        position: relative;
      }
    }
  }
}
</style>

