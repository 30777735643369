<template>
<div>
  <div id="frame-paper" :class="downloadable?'downloadable':''">
    <div id="frame-outer" ref="frameOuterContainer">
      <div id="frame" ref="frameContainer">
        <canvas id="frame-canvas" ref="frameCanvas"></canvas>
        <transition name="fade">
          <div id="frame-image" :class="(mood===null)?'active':''">
            <img ref="window" :src="require('@/assets/images/canvas/canvas-morning-normal.jpg')" v-if="time==='morning'"/>
            <img ref="window" :src="require('@/assets/images/canvas/canvas-day-normal.jpg')" v-else-if="time==='day'"/>
            <img ref="window" :src="require('@/assets/images/canvas/canvas-evening-normal.jpg')" v-else-if="time==='evening'"/>
            <img ref="window" :src="require('@/assets/images/canvas/canvas-night-normal.jpg')" v-else-if="time==='night'"/>
          </div>
        </transition>
        <div id="frame-animation" v-if="animation && view" ref="viewAnimation">
            <img :src="require('@/assets/images/canvas/view-'+view+'.png')" ref="viewAnimation1"/>
            <img v-if="view==='bird' || view==='rain' || view==='tree' || view==='star'" 
              :src="require('@/assets/images/canvas/view-'+view+'-animation-1.png')" ref="viewAnimation2"/>
            <img v-if="view==='rain' || view==='star'" 
              :src="require('@/assets/images/canvas/view-'+view+'-animation-2.png')" ref="viewAnimation3"/>
        </div>
        <div id="man-animation" v-if="animation && man" ref="manAnimation"  :style="'left:'+man.x+'px;top:'+man.y+'px'">
            <img :src="require('@/assets/images/canvas/man-'+man.action+'.png')"/>
        </div>
      </div>
    </div>
  </div>
  <div class="the-hand" ref="theHand" v-if="hand">
    <img :src="require('@/assets/images/hand.png')"/>
  </div>
  <div class="download-btn" v-if="downloadable">
    <div class="art-describe">
      <slot></slot>
    </div>
    <div v-if="downloadReady">
      <div class="art-actions">
        <ShareNetwork
            network="facebook"
            :url="shareReady"
            title="Art, Im in ให้ศิลปะดูแลใจ...ชวนไปสัมผัสประสบการณ์ง่ายๆ แค่คลิก"
            description="ร่วมส่ง 'ชุดศิลปะดูแลใจ' มอบให้ผู้ป่วยมะเร็ง 50 ท่าน"
            quote="Art, Im in ให้ศิลปะดูแลใจ"
            hashtags="#artimin, #ศิลปะดูแลใจ"
          >
            <button class="button block share-btn" type="button"><span>แชร์ </span><img :src="require('@/assets/images/logos/facebook.svg')" width="20" /></button>
        </ShareNetwork>

        <button class="button block button-morning" type="button" @click="download">ดาวน์โหลดรูปภาพ</button>
      </div>
    </div>
    <div v-else>
      . . .<br/>
      กำลังบันทึกรูป
    </div>
  </div>
</div>
</template>

<script>
import { fabric } from 'fabric'
import {gsap} from 'gsap';
import axios from 'axios'

export default {
  name: 'Frame',
  props: ['animation','downloadable','hand'],
  data() {
    return {
      canvas_w: 1000,
      canvas_h: 1000,
      canvas: null,
      frameImg: null,
      groundImg: null,
      moodGroundImg: null,
      viewImg: null,
      manImg: null,
      downloadReady: false,
      shareReady: false
    }
  },
  mounted() {
    const options = {
      width: this.canvas_w, height: this.canvas_h,
      preserveObjectStacking: true,
      controlsAboveOverlay: true,
      selection: false,
      backgroundColor: 'white'
    }
    this.canvas = new fabric.Canvas(this.$refs.frameCanvas,options);
    this.resize_canvas()
    this.setBackground()

    setTimeout(()=>{
      this.watchMood(this.$store.state.app.answers.mood)
      this.watchground(this.$store.state.app.answers.ground)
      this.watchView(this.$store.state.app.answers.view)
      this.watchMan(this.$store.state.app.answers.man)
      
      this.$store.watch(() => this.$store.getters['app/getMood'], this.watchMood )
      this.$store.watch(() => this.$store.getters['app/getGround'], this.watchground )
      this.$store.watch(() => this.$store.getters['app/getView'], this.watchView )
      this.$store.watch(() => this.$store.getters['app/getMan'], this.watchMan ) 
    },300)

    this.canvas.on('object:moving',  (e) => {
      var obj = e.target;
      // if object is too big ignore
      if(obj.currentHeight > obj.canvas.height || obj.currentWidth > obj.canvas.width){
        return;
      }
      obj.setCoords();
      // top-left  corner
      if(obj.getBoundingRect().top < 0 || obj.getBoundingRect().left < 0){
        obj.top = Math.max(obj.top, obj.top-obj.getBoundingRect().top);
        obj.left = Math.max(obj.left, obj.left-obj.getBoundingRect().left);
      }
      // bot-right corner
      if(obj.getBoundingRect().top+obj.getBoundingRect().height  > obj.canvas.height || obj.getBoundingRect().left+obj.getBoundingRect().width  > obj.canvas.width){
        obj.top = Math.min(obj.top, obj.canvas.height-obj.getBoundingRect().height+obj.top-obj.getBoundingRect().top);
        obj.left = Math.min(obj.left, obj.canvas.width-obj.getBoundingRect().width+obj.left-obj.getBoundingRect().left);
      }
    });
    this.canvas.on('object:moved',  (e) => {
      var obj = e.target;
      if(obj.id==="man") {
        this.$store.dispatch('app/setAnswer',{handle:"man",value:{action:this.man.action,x:obj.left,y:obj.top}})
      }
    })
    
    if(this.animation) {
      setTimeout(()=>{
        const {viewAnimation} = this.$refs
        if(viewAnimation) {
          if(this.view === 'star') {
            this.playStarAnimation()
          } else if(this.view === 'tree') {
            this.playTreeAnimation()
          } else if(this.view === 'bird') {
            this.playBirdAnimation()
          } else if(this.view === 'rain') {
            this.playRainAnimation()
          } else if(this.view === 'cloud') {
            this.playCloudAnimation()
          }
        }
      },1000)
    }

    if(this.downloadable) {
      setTimeout(()=>{
        this.save_download()
      },3000)
    }
  },
  methods: {
    next() {
      this.$router.replace('/choose-mood')
    },
    playStarAnimation() {
      const {viewAnimation1,viewAnimation2,viewAnimation3} = this.$refs
      let viewani = gsap.timeline({repeat: -1,yoyo:false, repeatDelay: 1})
      viewani.from(viewAnimation2,{duration: 1,opacity:0})
      viewani.from(viewAnimation3,{duration: 1,opacity:0})
      viewani.from(viewAnimation1,{duration: 1,opacity:0})
      viewani.to(viewAnimation1,{duration: 1,opacity:0,delay:1})
      viewani.to(viewAnimation1,{duration: 1,opacity:1})

      viewani.to(viewAnimation1,{duration: 1,opacity:0,delay:1})
      viewani.to(viewAnimation2,{duration: 1,opacity:0},"-=1")
      viewani.to(viewAnimation3,{duration: 1,opacity:0},"-=1")
    },
    playTreeAnimation() {
      const {viewAnimation1,viewAnimation2} = this.$refs
      let viewani = gsap.timeline({repeat: -1,yoyo:true, repeatDelay: 0})
      viewani.to(viewAnimation1,{duration: 1,opacity:0,delay:1})
      viewani.from(viewAnimation2,{duration: 0.8,opacity:0},"-=1")
    },
    playBirdAnimation() {
      const {viewAnimation1,viewAnimation2} = this.$refs
      let viewani = gsap.timeline({repeat: -1,yoyo:true, repeatDelay: 0})
      viewani.to(viewAnimation1,{duration: 1,opacity:0,delay:1})
      viewani.from(viewAnimation2,{duration: 0.6,opacity:0},"-=1")
    },
    playRainAnimation() {
      const {viewAnimation1,viewAnimation2,viewAnimation3} = this.$refs
      let viewani = gsap.timeline({repeat: -1,yoyo:false, repeatDelay: 1})
      viewani.from(viewAnimation2,{duration: 1,opacity:0})
      viewani.from(viewAnimation3,{duration: 1,opacity:0})
      viewani.from(viewAnimation1,{duration: 1,opacity:0})

      viewani.to(viewAnimation2,{duration: 1,opacity:0,delay:1})
      viewani.to(viewAnimation3,{duration: 1,opacity:0},"-=1")
      viewani.to(viewAnimation1,{duration: 1,opacity:0},"-=1")
    },
    playCloudAnimation() {
      const {viewAnimation1} = this.$refs
      let viewani = gsap.timeline({repeat: -1,yoyo:true, repeatDelay: 0})
      viewani.to(viewAnimation1,{duration: 3,left:-50})
      viewani.to(viewAnimation1,{duration: 3,left:0})
    },
    setBackground() {
      let str = (this.time)?this.time:'morning'
      str = str+'-'+((this.mood)?this.mood:'normal')
      fabric.Image.fromURL(require('@/assets/images/canvas/canvas-'+str+'.jpg'), (img) => {
        //fabric.Image.fromURL(require('@/assets/images/q7-prepare/desk.png'), function(img) {
        this.canvas.remove(this.frameImg)
        this.frameImg = img.set({ left: 0, top: 0, width:this.canvas_w, height: this.canvas_h, selectable:false})
        this.canvas.add(this.frameImg)
        this.frameImg.moveTo(2)
        this.save_canvas()
        this.canvas.renderAll()
      });
    },
    download() {
      window.location = this.downloadReady
    },
    share() {
      //window.location = this.shareReady
      if (navigator.share) {
        navigator.share({
          title: document.title,
          text: "Hello World",
          url: this.shareReady
        })
        .then(() => console.log('Successful share'))
        .catch(error => console.log('Error sharing:', error));
      }
    },
    watchMood(val) {
      if(val) {
        this.setBackground()
      } else if(this.frameImg) {
        this.frameImg.filters = []
        this.frameImg.applyFilters()
        this.canvas.renderAll()
      }
      this.save_canvas()
    },
    watchground(val) {
      if(val) {
        if(this.groundImg) {
          this.canvas.remove(this.groundImg)
        }
        this.canvas.remove(this.groundImg)
        let strTime = (this.time)?this.time:'morning'
        fabric.Image.fromURL(require('@/assets/images/canvas/ground-'+val+'-'+strTime+'.png'), (img) => {
          this.groundImg = img.set({ left: 0, top: 0, opacity:0,width:this.canvas_w, height: this.canvas_h, selectable:false})
          this.canvas.add(this.groundImg)
          this.groundImg.moveTo(4)
          this.groundImg.animate('opacity', '1', {
            duration: 1000,
            onChange: this.canvas.renderAll.bind(this.canvas),
            onComplete: ()=>{
              this.save_canvas()
            }
          });
        });
      } else if(this.groundImg) {
        this.groundImg.animate('opacity', '0', {
          duration: 1000,
          onChange: this.canvas.renderAll.bind(this.canvas),
          onComplete: () => {
            this.canvas.remove(this.groundImg);
            this.save_canvas()
          }
        });
      }
    },
    watchView(val) {
      if(val) {
        if(this.viewImg) {
          this.canvas.remove(this.viewImg)
        }
        this.canvas.remove(this.viewImg)
        fabric.Image.fromURL(require('@/assets/images/canvas/view-'+val+'.png'), (img) => {
          this.viewImg = img.set({ id: 'view',left: 0, top: 0, opacity:0,width:this.canvas_w, height: this.canvas_h, selectable:false})
          this.canvas.add(this.viewImg)

          if(this.animation)this.viewImg.moveTo(0)
          else this.viewImg.moveTo(9)

          this.viewImg.animate('opacity', '1', {
            duration: 1000,
            onChange: this.canvas.renderAll.bind(this.canvas),
            onComplete: ()=>{
              this.save_canvas()
            }
          });
        });
      } else if(this.viewImg){
        this.viewImg.animate('opacity', '0', {
          duration: 1000,
          onChange: this.canvas.renderAll.bind(this.canvas),
          onComplete: () => {
            this.canvas.remove(this.viewImg);
            this.save_canvas()
          }
        });
      }
    },
    watchMan(val) {
      let opa = 0
      if(val) {
        if(this.manImg) {
          this.canvas.remove(this.manImg)
          opa = 1
        }
        fabric.Image.fromURL(require('@/assets/images/canvas/man-'+val.action+'.png'), (img) => {
          this.manImg = img.set({ id: 'man',left: val.x, top: val.y, opacity:opa, selectable: true,hasBorders: false, hasControls: false, originX: "center", originY: "center" })
          this.canvas.add(this.manImg)
          if(this.animation)this.manImg.moveTo(1)
          else this.manImg.moveTo(10)
          if(opa===0) {
            this.manImg.animate('opacity', '1', {
              duration: 1000,
              onChange: this.canvas.renderAll.bind(this.canvas),
              onComplete: ()=>{
                this.save_canvas()
              }
            });
          } else {
            this.canvas.renderAll()
            this.save_canvas()
          }
        });
      } else if(this.manImg) {
        this.manImg.animate('opacity', '0', {
          duration: 1000,
          onChange: this.canvas.renderAll.bind(this.canvas),
          onComplete: () => {
            this.canvas.remove(this.manImg);
            this.save_canvas()
          }
        });
      }
    },
    resize_canvas() {
      let container = this.$refs.frameContainer
      let outerContainer = this.$refs.frameOuterContainer
      var editor_width = container.offsetWidth;

      var editor_scale = Math.round((editor_width * 100) / this.canvas_w)/100;
      if (editor_width <= this.canvas_w) {
        container.style.transform = "scale("+ editor_scale + ")";
        outerContainer.style.width = Math.ceil(this.canvas_w * editor_scale)+'px'
        outerContainer.style.height = Math.ceil(this.canvas_w * editor_scale)+'px'
      }
    },
    save_canvas() {
      return null
      /*
      const canv = this.canvas.toJSON()
      this.$store.dispatch('app/setCanvas',canv)
      */
    },
    save_download() {
      const url = 'https://artimin.artforcancerbyireal.com';
      var json = this.canvas.toJSON();
      //var svg = canvas.toSVG();
      if(this.animation) {
        this.viewImg.moveTo(9)
        this.manImg.moveTo(10)
        setTimeout(()=>{
          this.viewImg.moveTo(0)
          this.manImg.moveTo(0)
        },1000)
      }
      try {
        var image_data = this.canvas.toDataURL({format:'jpeg'});
        var data = new FormData();
        data.append('json',JSON.stringify(json));
        data.append('data',image_data);
        data.append('answers',JSON.stringify(this.$store.state.app.answers))

        //data.append('svg',svg);
        axios({
          url: url+'/crafty/save',
          method: 'POST',
          headers: {"Content-Type": "multipart/form-data"},
          data: data
        }).then((res) => {
          if(res && res.data && res.data.success===1) {
            this.downloadReady = url+'/create/view/'+res.data.uniq;
            this.shareReady = url+'/create/share/'+res.data.uniq;
          } else {
              alert('Error saving image');
          }
        }).catch(function (error) {
          alert('Error download image');
          console.log(error)
        })
      } catch (err) {
        console.log(err)
      }
    }
  },
  computed: {
    tl_enter() {
      return gsap.timeline()
    },
    time() {
      return this.$store.state.app.answers.time
    },
    mood() {
      return this.$store.state.app.answers.mood
    },
    view() {
      return this.$store.state.app.answers.view
    },
    man() {
      return this.$store.state.app.answers.man
    }
  },
  beforeUnmount() {
    this.tl_enter.pause()
    if(this.hand) {
      const {theHand} = this.$refs
      let exitani = gsap.timeline({repeat: 0})
      exitani.to(theHand,{duration: 1,opacity:0})
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#frame-paper {
  border:solid 10px #fff;
  border-top-left-radius: 20px 10px;
  border-top-right-radius: 10px 50px;
  border-bottom-right-radius: 50px 10px;
  border-bottom-left-radius:10px 30px;
  box-shadow: 1px 1px 4px 5px rgba(0,0,0,0.05);
}
#frame {
  transform-origin: top left;
  position: relative;
  #frame-image {
    z-index: 9999;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.5s;
    pointer-events: none;
  }
  #frame-image.active {
    opacity: 1;
    transition: opacity 0.5s;
  }
  #frame-canvas {
    position: relative;
  }
  #frame-animation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;

    &>img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  #man-animation {
    position: absolute;
    top: 0;
    left: 0;
    img {
      position: relative;
      transform: translate(-50%,-50%);
    }
  }
}
.download-btn {
  margin: 20px 0 10px;
}
.art-describe {
  margin: 0 0 20px;
  font-size: 0.9em;
  line-height: 1.5em;
  span {
    white-space: nowrap;
  }
}
.art-actions {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 10px;
}
.share-btn {
  display: flex !important;
  align-items: center;
  justify-content: center;

  img {
    margin: -3px 0 0 10px;
  }
}
.the-hand {
  position: absolute;
  bottom: -40px;
  right: -50px;
  z-index: 9999;
  img {
    width: 120px;
  }
}
</style>
