const initAns = {
  ans1: null,
  time: null,
  mood: null,
  ground: null,
  view: null,
  man: null
}
const state = {
  current_scene: null,
  answers: initAns,
  music: null,
  canvas: null
}

const actions = {
  stopMusic(context) {
    context.commit('music',null)
  },
  setMusic(context, audio) {
    context.commit('music',audio)
  },
  setCurrentScene(context, scene) {
    context.commit('scene',scene)
  },
  setAnswer(context, payload) {
    context.commit('answer',payload)
  },
  clearAnswer(context) {
    context.commit('clearAns')
  },
  setCanvas(context, json) {
    context.commit('canvas',json)
  }
}

const mutations = {
  music(state,audio) {
    state.music = audio
  },
  scene(state,scene) {
    state.scene = scene
  },
  canvas(state,json) {
    state.canvas = json
  },
  clearAns(state) {
    state.answer = initAns
  },
  answer(state,payload) {
    state.answers[payload.handle] = payload.value
  }
}

const getters = {
  getMusic(state) {
    return state.music
  },
  getMood(state) {
    return state.answers.mood
  },
  getGround(state) {
    return state.answers.ground
  },
  getView(state) {
    return state.answers.view
  },
  getMan(state) {
    return state.answers.man
  },
  getCanvas(state) {
    return state.canvas
  }
}

export const app = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}