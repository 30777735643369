<template>
<div>
  <div id="ani-frame" ref="backgroundFrame" @click="next">
    <flying-bird :duration="duration" :loop="true"/>
    <div id="helper" ref="helperText">แตะเพื่อไปต่อ</div>
  </div>
</div>
</template>

<script>
import FlyingBird from '@/components/FlyingBird';
import {gsap} from 'gsap';
export default {
  name: 'Intro',
  components: {
    FlyingBird
  },
  data() {
    return {
      duration: 20
    }
  },
  mounted() {
    const { backgroundFrame,helperText } = this.$refs

    this.tl_intro_scene.to(backgroundFrame,{backgroundSize:'auto 100%',duration: this.duration})

    this.tl_helper.from(helperText,{bottom:0,duration: 2,delay:2,opacity:0})
    this.tl_helper.to(helperText,{y:5,duration: 1,repeat: -1, yoyo: true,delay:2})

    this.$store.dispatch('app/setMusic',require('@/assets/audio/intro-1.mp3'))
  },
  beforeUnmount() {
    this.tl_intro_scene.pause()
    this.tl_helper.pause()
  },
  methods: {
    next() {
      this.$router.replace('/greeting')
    },
  },
  computed: {
    tl_intro_scene (){
      return gsap.timeline({repeat: 0, yoyo: false});
    },
    tl_helper() {
      return gsap.timeline();
    }
  }
}
</script>

<style lang="scss" scoped>
#ani-frame {
  width: 100%;
  height: 100%;
  background: no-repeat url('~@/assets/images/q1-intro/outside-view.jpg') center center;
  background-size: auto 120%;
  position: absolute;
  overflow: hidden;

  #helper {
    position: absolute;
    bottom: 30vh;
    text-align: center;
    width: 100%;
    font-size: 1.5em;
    text-shadow: 1px 1px 3px rgba(0,0,0,0.4);
    color: #fff;
  }
}
</style>

