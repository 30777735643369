<template>
  <div id="choose-frame" ref="backgroundFrame">
    <div id="choose-image">
      <div ref="frame-image">
        <frame :hand="true"/>
      </div>
    </div>
    <div id="choose-form">
      <transition name="slidefade">
        <div  class="intro-messages" v-if="view===null">
          <div  class="intro-message" ref="introMessage">ที่นั่นเธอเห็นอะไรอีกบ้าง</div>
          <div class="multi-buttons" ref="q2Buttons">
            <div>
              <button class="button block" type="button" @click="selectView('tree')">ต้นไม้สูงใหญ่</button>
              <button class="button block" type="button" @click="selectView('bird')">ฝูงนกโบยบิน</button>
            </div>
            <div>
              <button class="button block" type="button" @click="selectView('cloud')">เมฆเบาปลิวลม</button>
              <button class="button block" type="button" @click="selectView('star')">แสงดาวระยิบระยับ</button>
            </div>
            <div>
              <button class="button block" type="button" @click="selectView('rain')">สายฝนโปรยปราย</button>
            </div>
          </div>
        </div>
        <div class="intro-messages" v-else>
          <button ref="nextBtn" class="button-text" type="button" @click="next">ไปต่อ &#xbb;</button>
          <br/>
          <button ref="nextBtn" class="button-text button-text-sm" type="button" @click="clear">เลือกใหม่</button>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>

import Frame from '@/components/Frame';
import {gsap} from 'gsap';
//gsap.globalTimeline.timeScale(4)
export default {
  name: 'chooseview',
  components: {
    Frame
  },
  data() {
  },
  mounted() {

    if(this.$store.state.app.answers.time === null || this.$store.state.app.answers.mood === null || this.$store.state.app.answers.ground === null) {
      window.location = '/'
    }

    //this.$store.dispatch('app/stopMusic')
    const {introMessage,q2Buttons} = this.$refs
    this.tl_enter.from(introMessage,{duration: 2,opacity:0,top: 20,delay:2})
    this.tl_enter.from(q2Buttons,{duration: 1.5,delay:0,opacity:0,top: 20})
  },
  methods: {
    next() {
      this.$router.replace('/choose-man')
    },
    selectView(view) {
      this.$store.dispatch('app/setAnswer',{handle:"view",value:view})
    },
    clear() {
      this.$store.dispatch('app/setAnswer',{handle:"view",value:null})
    }
  },
  beforeUnmount() {
    this.tl_enter.pause()
  },
  computed: {
    tl_enter() {
      return gsap.timeline();
    },
    view() {
      return this.$store.state.app.answers.view
    }
  }
}
</script>

<style lang="scss" scoped>
#choose-frame {
  width: 100%;
  height: 100%;

  background: no-repeat url('~@/assets/images/white-paper.jpg') top center;
  background-size: cover;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: top;
  align-content: center;
  text-align: center;
  line-height: 1.7em;
  flex-direction: column;
  padding-top: 40px;
  
  @media only screen and (max-width: 500px) {
    font-size: 0.93em;
    padding-top: 10px;
  }
  #choose-image {
    width: 450px;
    height: 450px;
    position: relative;
    margin: 0 0 20px 0;
    img {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    @media only screen and (max-width: 500px) {
      margin: 0 0 10px;
      width: 90vw;
      height: 90vw;
    }
  }
  #choose-image-1 {
    opacity: 0;
  }
  #choose-image-2 {
    opacity: 0;
  }

  .button-text {
    margin: 0;
  }

  #choose-form {
    position: relative;
    width: 100%;
    top: 20px;
  }
  .intro-messages {
    margin: 0;
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    .intro-message {
      position: relative;
    }
  }
  .multi-buttons {
    width: 500px;
    position: relative;
    opacity: 1;
    top: 0;

    &>div {
      margin: 0;
      display: flex;
      justify-content: center;
      &>button {
        margin: 5px;
      }
      &>button:only-child {
        width: 49%;
      }
      @media only screen and (max-width: 500px) {
        display: block;
        width: 300px;
        margin: 0 auto;
        &>button {
          width: 100% !important;
        }
      }
    }
    text-align:center;
  }
}
</style>

