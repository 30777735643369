<template>
  <div id="greeting-frame" ref="backgroundFrame">
    <div id="greeting-image" ref="greetingImage">
      <img  id="greeting-image-1" ref="greetingImage1" :src="require('@/assets/images/q2-3-greeting/bird-window.png')"/>
      <img  id="greeting-image-2" ref="greetingImage2" :src="require('@/assets/images/q2-3-greeting/bird-window-2.png')"/>
      <img  id="greeting-image-3" ref="greetingImage3" :src="require('@/assets/images/q2-3-greeting/bird-man.png')"/>
    </div>
    <div id="greeting-form" ref="greetingForm">
      <transition name="slidefade">
        <div class="greeting-form-item" id="greeting-1" ref="greeting1" v-show="step===1">
          <span ref="greeting1message">เมื่อกี้เธอทำอะไรอยู่เหรอ?</span>
          <div ref="greeting1form">
            <input type="text" placeholder="พิมพ์คำตอบ" v-model="ans1"/>
            <button class="button-text" type="submit" @click="ans">ไปต่อ &#xbb;</button>
          </div>
        </div>
      </transition>
      <transition name="slidefade">
        <div class="greeting-form-item" id="greeting-2" v-show="step===2">
          <div class="multi-message" ref="multiMessage">
            <div ref="q2msg2">พอว่างสักครู่มั้ย?</div>
            <div ref="q2msg3">อยากชวนเธอมาทำงานศิลปะกัน</div>
          </div>
          <div class="multi-buttons" ref="q2Buttons">
            <button class="button block" type="button" @click="goToStep(3)">ได้สิ</button>
            <button class="button block" type="button" @click="goToStep(4)">อย่าเลย ฉันวาดรูปไม่เป็นหรอก</button>
            <button class="button block" type="button" @click="goToStep(5)">ไม่เลย ฉันยังไม่พร้อม</button>
          </div>
        </div>
      </transition>
      <transition name="slidefade">
        <div class="greeting-form-item" id="greeting-3" v-show="step===3">
          <div>
            <div class="multi-message">
              <div ref="s3msg1">พร้อมนะ</div>
            </div>
          </div>
        </div>
      </transition>
      <transition name="slidefade">
        <div class="greeting-form-item" id="greeting-4" v-show="step===4">
          <div>
            <div class="multi-message">
              <div ref="s4msg1">นั่นไม่ใช่ปัญหาเลย</div>
              <div ref="s4msg2">ขอแค่เธอเปิดใจและลองดู</div>
            </div>
          </div>
        </div>
      </transition>
      <transition name="slidefade">
        <div class="greeting-form-item" id="greeting-4" v-show="step===5">
          <div>
            <div class="multi-message">
              <div ref="s5msg1">ไม่เป็นไรเลย</div>
              <div ref="s5msg2">เจอกันใหม่เมื่อพร้อมนะ</div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>

import {gsap} from 'gsap';
export default {
  name: 'Home',
  components: {
    
  },
  data() {
    return {
      ans1: this.$store.state.app.answers.ans1,
      step: 1
    }
  },
  mounted() {
    const { greetingImage,greeting1message,greeting1form } = this.$refs
    this.tl_enter.from(greetingImage,{opacity:0,y:20,duration:1,delay:1})
    this.tl_enter.from(greeting1message,{opacity:0,y:20,duration:1},'-=0.5')
    this.tl_enter.from(greeting1form,{opacity:0,y:20,duration:1.2},'-=0.8')

    this.$store.dispatch('app/setMusic',require('@/assets/audio/intro-2.mp3'))
  },
  methods: {
    goToStep(step) {
      this.step = step
      if(step === 3) {
        const { s3msg1 } = this.$refs
        this.tl_message.to(s3msg1,{opacity:1,top:0,duration:1,delay:1,onComplete:()=>{
          setTimeout(()=>this.next(),3000)
        }})
        //setTimeout(()=>this.next(),5000)
      } else if(step === 4) {
        const { greetingImage2,greetingImage3,s4msg1,s4msg2 } = this.$refs
        this.tl_message.to(greetingImage2,{opacity:0,duration:1})
        this.tl_message.to(greetingImage3,{opacity:1,duration:2,delay:1})
        this.tl_message.to(s4msg1,{opacity:1,top:0,duration:1,delay:0.3})
        this.tl_message.to(s4msg2,{opacity:1,top:0,duration:1,delay:1,onComplete:()=>{
          setTimeout(()=>this.next(),3000)
        }})
      } else if(step === 5) {
        const { s5msg1,s5msg2 } = this.$refs
        const msg_transition = {opacity:1,top:0,duration:1};
        const msg_transition_leave = {opacity:0,top:'-20px',delay:1,duration:0.5};
        this.tl_message.to(s5msg1,{...msg_transition,...{delay:1}})
        this.tl_message.to(s5msg1,msg_transition_leave)
        this.tl_message.to(s5msg2,msg_transition)
        this.tl_message.to(s5msg2,msg_transition_leave)
        setTimeout(()=>{
          this.$router.replace('/outtro')
        },6000)
      } 
    },
    next() {
      this.$router.replace('/poem')
    },
    ans() {
      this.step=2
      setTimeout(()=>this.playQ1Msg(),150)
      //const { greetingImage,greeting1 } = this.$refs
    },
    playQ1Msg() {
        const { q2msg2,q2msg3,q2Buttons,greetingImage1,greetingImage2,multiMessage } = this.$refs

        this.tl_greeting.to(greetingImage1,{opacity:0,duration:1.5})
        this.tl_greeting.to(greetingImage2,{opacity:1,duration:2},'-=1')

        const msg_transition_leave = {opacity:0,y:-20,duration:1};
        const msg_transition = {opacity:1,y:-10,duration:1,delay:0.3}

        this.tl_message.to(q2msg2,msg_transition)
        this.tl_message.to(q2msg3,msg_transition)
        this.tl_message.to(q2msg2,msg_transition_leave,'-=1')
        this.tl_message.to(multiMessage,{y:-40,duration:2},'-=1')
        this.tl_message.to(q2Buttons,{opacity:1,y:-60,duration:2})
    }
  },
  beforeUnmount() {
    this.tl_enter.pause()
    this.tl_greeting.pause()
    this.tl_message.pause()
  },
  computed: {
    tl_enter() {
      return gsap.timeline();
    },
    tl_greeting() {
      return gsap.timeline();
    },
    tl_message() {
      return gsap.timeline({delay:2});
    }
  }
}
</script>

<style lang="scss">
#greeting-frame {
  width: 100%;
  height: 100%;

  background: no-repeat url('~@/assets/images/white-paper.jpg') top center;
  background-size: cover;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 600px) {
    display: block;
    text-align: center;
  }

  #greeting-image {
    margin: 0 50px 0 0;
    img {
      max-width: 280px;
    }
    @media only screen and (max-width: 600px) {
      margin: 40px 0 20px;
      img {
        max-width: 220px;
      }
    }
  }
  #greeting-image-2,
  #greeting-image-3 {
    opacity: 0;
    position: absolute;
    // top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  #greeting-form {
    text-align: center;
    position: relative;
    width: 320px;

    @media only screen and (max-width: 600px) {
      margin: 0 auto;
    }
    span {
      display: block;
      font-size: 1.3em;
      line-height: 1.3em;
      margin: 0 0 5px 0;
    }
    input[type=text] {
      margin: 5px 0;
      border: 2px solid $color-text;
      color: $color-text;
      border-radius: 5px;
      font-size: 1em;
      line-height: 2em;
      text-align: center;
      width: 300px;
      background: transparent;
      padding: 10px 10px 5px;
      transition: border-color 0.4s;
      overflow: normal;
      &::placeholder {
        color: lighten($color-text,10);
        line-height: 2em;
      }
      &:active,
      &:focus {
        outline: none;
        border-color: $color-text-secondary;
      }
    }
    button.button-text {
      display: block;
      margin: 10px auto;
    }
    .greeting-form-item {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      @media only screen and (max-width: 600px) {
        height: auto;
      }

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  .multi-message {
    position: relative;
    width: 350px;
    text-align: center;
    margin-top: 20px;
    &>div {
      text-align: center;
      width: 100%;
      opacity:0;
      display:block;
      font-size: 1.3em;
      line-height: 1.3em;
    }
  }
  .multi-buttons {
    width: 100%;
    position: relative;
    opacity: 0;
    top: 20px;
    button.button {
      margin: 10px 0;
    }
  }
}
</style>