import { createStore } from 'vuex'
import { app } from './modules/app';
export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    app
  }
})
