<template>

  <div id="prepare-frame" ref="backgroundFrame">
    <div id="prepare-backdrop" ref="backgroundBackdrop"></div>
    <div>
      <div id="prepare-image" ref="prepareImage">
        <img id="prepare-image-1" ref="prepareImage1" :src="require('@/assets/images/q7-prepare/desk.png')"/>
      </div>
      <div id="intro-messages" ref="introMessages">
        <span ref="introMessage1">ฉันเตรียมอุปกรณ์เรียบร้อยแล้วล่ะ</span>
        <br/>
        <span ref="introMessage2">เรามาเริ่มกัน</span>
        <br/>
        <button ref="nextBtn" class="button-text" type="button" @click="next">ไปต่อ &#xbb;</button>
      </div>
    </div>
  </div>
</template>

<script>

import {gsap} from 'gsap';
//gsap.globalTimeline.timeScale(4)
export default {
  name: 'prepare',
  components: {
  },
  data() {
  },
  mounted() {
    //this.$store.dispatch('app/stopMusic')
    const {prepareImage1} = this.$refs
    const {introMessage1,introMessage2,nextBtn} = this.$refs

    // window + bird
    this.tl_enter.to(prepareImage1,{duration: 2,opacity:1})
    // intro msg
    this.tl_enter.from(introMessage1,{duration: 1.5,delay:0.5,opacity:0,y: 20},'-=1.5')
    this.tl_enter.from(introMessage2,{duration: 1.5,opacity:0,y: 20})
    
    this.tl_enter.from(nextBtn,{duration: 1.5,delay:0,opacity:0,y: 20})
  },
  methods: {
    next() {
      this.$router.replace('/choose-time')
    }
  },
  beforeUnmount() {
    this.tl_enter.pause()
  },
  computed: {
    tl_enter() {
      return gsap.timeline();
    }
  }
}
</script>

<style lang="scss" scoped>
#prepare-backdrop {
  background: #222;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}
#prepare-frame {
  width: 100%;
  height: 100%;

  background: no-repeat url('~@/assets/images/white-paper.jpg') top center;
  background-size: cover;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
  line-height: 1.7em;

  @media only screen and (max-width: 500px) {
    font-size: 0.93em;
  }
  #prepare-image {
    width: 500px;
    height: 350px;
    position: relative;
    img {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    @media only screen and (max-width: 500px) {
      width: 90vw;
      height: 65vw;
      padding: 0;
      margin: 0 auto;
    }
  }
  #prepare-image-1 {
    opacity: 0;
  }
  #prepare-image-2 {
    opacity: 0;
  }

  .button-text {
    margin: 40px 0 0 0;
  }

  #intro-messages {
    margin: 30px 0 0 0;
    span {
      display: inline-block;
      position: relative;
    }
  }
}
</style>

