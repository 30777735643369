import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Q0-Home.vue'
import Intro from '../views/Q1-Intro.vue'
import Greeting from '../views/Q2-3-Greeting.vue'
import Outtro from '../views/Q3-Outtro.vue'
import Poem from '../views/Q4-Poem.vue'
import Meditate from '../views/Q5-6-Meditate.vue'
import Prepare from '../views/Q7-Prepare.vue'
import ChooseTime from '../views/Q8-ChooseTime.vue'
import ChooseMood from '../views/Q10-ChooseMood.vue'
import Rest from '../views/Q12-Rest.vue'
import ChooseGround from '../views/Q14-ChooseGround.vue'
import ChooseView from '../views/Q15-ChooseView.vue'
import ChooseMan from '../views/Q16-ChooseMan.vue'
import Result from '../views/Q17-Result.vue'
import End from '../views/Q19-End.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/intro',
    name: 'Intro',
    component: Intro
  },
  {
    path: '/outtro',
    name: 'Outtro',
    component: Outtro
  },
  {
    path: '/greeting',
    name: 'Greeting',
    component: Greeting
  },
  {
    path: '/poem',
    name: 'Poem',
    component: Poem
  },
  {
    path: '/meditate',
    name: 'Meditate',
    component: Meditate
  },
  {
    path: '/prepare',
    name: 'Prepare',
    component: Prepare
  },
  {
    path: '/choose-time',
    name: 'ChooseTime',
    component: ChooseTime
  },
  {
    path: '/choose-mood',
    name: 'ChooseMood',
    component: ChooseMood
  },
  {
    path: '/rest',
    name: 'Rest',
    component: Rest
  },
  {
    path: '/choose-ground',
    name: 'ChooseGround',
    component: ChooseGround
  },
  {
    path: '/choose-view',
    name: 'ChooseView',
    component: ChooseView
  },
  {
    path: '/choose-man',
    name: 'ChooseMan',
    component: ChooseMan
  },
  {
    path: '/result',
    name: 'Result',
    component: Result
  },
  {
    path: '/end',
    name: 'End',
    component: End
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
