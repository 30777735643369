<template>
  <div id="peom-frame" ref="backgroundFrame" @click="seek">
    <div class="poem-container">
      <div>
        <div class="butterflys">
          <div><img class="butterfly1" ref="butterfly1" :src="require('@/assets/images/poem/butterfly.png')"/></div>
          <div><img class="butterfly2" ref="butterfly2" :src="require('@/assets/images/poem/butterfly.png')"/></div>
          <div><img class="butterfly3" ref="butterfly3" :src="require('@/assets/images/poem/butterfly.png')"/></div>
        </div>
        <div class="poems">
          <div ref="poem1">แด่เธอ ผู้กำลังหาคำตอบของคำถาม</div>
          <div ref="poem2">ในทุกเรื่องมีที่มา และที่ไป</div>
          <div ref="poem3">ในทุกคำถามมีคำตอบของมันเอง</div>
          <div ref="poem4">เพียงแต่อาจไม่ใช่ตอนนี้, เดี๋ยวนี้</div>
          <br/>
          <div ref="poem5">ยากที่ผีเสื้อจะบินเข้าหาผู้ที่วิ่งไล่ตามมัน</div>
          <div ref="poem6">คนที่นั่งลงและสัมผัสความงาม</div>
          <div ref="poem7">ของดอกไม้ต่างหากเล่า</div>
          <div ref="poem8">จึงจะได้เจอผีเสื้อเหล่านั้น</div>
        </div>
        <button ref="nextBtn" class="button-text" type="button" @click="next">ไปต่อ &#xbb;</button>
      </div>
    </div>
  </div>
</template>

<script>

import {gsap} from 'gsap';
//gsap.globalTimeline.timeScale(4)
export default {
  name: 'Poem',
  components: {
  },
  data() {
    return {}
  },
  mounted() {
    const {nextBtn,poemImage,butterfly1,butterfly2,butterfly3} = this.$refs

    this.tl_butterfly.from(poemImage,{bottom:-20,duration: 2,delay:0,opacity:0})
    this.tl_butterfly.from(butterfly2,{bottom:-20,duration: 2,delay:0,opacity:0})
    this.tl_butterfly.from(butterfly1,{bottom:-20,duration: 2,delay:3,opacity:0})
    this.tl_butterfly.from(butterfly3,{bottom:-20,duration: 2,delay:3,opacity:0})

    for(let i=1;i<=8;i++) {
      this.tl_enter.from(this.$refs['poem'+i], {duration: 1.7, opacity:0, y:20},'poem'+i);
    }

    this.tl_enter.from(nextBtn,{bottom:-20,duration: 1.5,delay:2,opacity:0})
    //this.$store.dispatch('app/stopMusic')
  },
  methods: {
    next() {
      this.$router.replace('/meditate')
    },
    seek() {
      this.tl_enter.seek(this.tl_enter.nextLabel())
    }
  },
  beforeUnmount() {
    this.tl_enter.pause()
  },
  computed: {
    tl_enter() {
      return gsap.timeline({delay:3});
    },
    tl_butterfly() {
      return gsap.timeline({delay:0});
    }
  }
}
</script>

<style lang="scss" scoped>
#peom-frame {
  width: 100%;
  height: 100%;

  background: no-repeat url('~@/assets/images/white-paper.jpg') top center;
  background-size: cover;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.7em;

  #helper {
    position: absolute;
    bottom: 20px;
    text-align: center;
    width: 100%;
    font-size: 0.9em;
    color: #fff;
  }

  .button-text {
    margin: 40px 0 0 0;
  }

  .poem-container {
    margin: 0 auto;
    gap: 40px;

    @media only screen and (max-width: 800px) {
      grid-template-columns: 1fr;
      max-width: 100%;
    }

    .poem-image {
      width: 100%;
      padding: 20px 0 0 0;
      img {
        width: 100%;
        position: relative;
      }

      @media only screen and (max-width: 800px) {
        display: none;
      }
    }
  }
  .butterflys {
    display: flex;
    max-width: 400px;
    margin: 0 auto 20px;
    img {
      width: 100%;
    }
    .butterfly1 {
      transform: rotate(35deg);
      position: relative;
    }
    .butterfly2 {
      transform: rotate(60deg);
      position: relative;
      top: -20px;
    }
    .butterfly3 {
      transform: rotate(150deg);
      position: relative;
    }
  }
}
</style>

