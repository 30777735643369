<template>

  <div id="meditate-frame" ref="backgroundFrame" @click="seek">
    <div id="meditate-backdrop" ref="backgroundBackdrop"></div>
    <div>
      <div id="meditate-image" ref="meditateImage">
        <img id="meditate-image-1" ref="meditateImage1" :src="require('@/assets/images/q5-6-meditate/l1-window.png')"/>
        <img id="meditate-image-2" ref="meditateImage2" :src="require('@/assets/images/q5-6-meditate/l2-bird.png')"/>
        <img id="meditate-image-3" ref="meditateImage3" :src="require('@/assets/images/q5-6-meditate/l3-bird-back.png')"/>
        <img id="meditate-image-4" ref="meditateImage4" :src="require('@/assets/images/q5-6-meditate/l4-light.png')"/>
      </div>
      <div id="intro-messages" ref="introMessages">
        <span ref="introMessage1">เริ่มที่อยู่ด้วยกันตรงนี้</span>
        &nbsp;
        <span ref="introMessage2">ตอนนี้</span>
        &nbsp;
        <span ref="introMessage3">เพียงครู่</span>
      </div>

      <div id="meditate-messages" ref="meditateMessages">
        <span ref="meditateMessage1">อยากให้เธอลองนั่งในท่าที่สบาย ผ่อนคลาย</span>
        <br/>
        <span ref="meditateMessage2">อยากให้เธอลองมองไปที่แสงนั้น</span>
        <br/>
        <span ref="meditateMessage3">หายใจ เข้า ออกช้าๆ ตามแสงนั้นไป</span>
        <br/>
        <span ref="meditateMessage4">ค่อยๆ หายใจเข้าพร้อมกับแสงสว่างที่เกิดขึ้น</span>
        <br/>
        <span ref="meditateMessage5">และหายใจออก พร้อมแสงที่มืดลง เข้าออกสามครั้ง</span>
        <br/>
        <button ref="nextBtn" class="button-text" type="button" @click="next">ไปต่อ &#xbb;</button>
      </div>
      <audio ref="speakAudio" :src="require('@/assets/audio/meditate-speak.mp3')" preload mute></audio>
    </div>
  </div>
</template>

<script>

import {gsap} from 'gsap';
//gsap.globalTimeline.timeScale(4)
export default {
  name: 'Meditate',
  components: {
  },
  data() {
  },
  mounted() {
    //this.$store.dispatch('app/stopMusic')
    const {meditateImage1,meditateImage2,meditateImage3,meditateImage4,backgroundBackdrop} = this.$refs
    const {introMessages,introMessage1,introMessage2,introMessage3,nextBtn} = this.$refs
    const {meditateMessage1,meditateMessage2,meditateMessage3,meditateMessage4,meditateMessage5} = this.$refs

    // window + bird
    this.tl_enter.to(meditateImage1,{duration: 2,opacity:1})
    this.tl_enter.to(meditateImage2,{duration: 2,opacity:1},'-=1')
    // intro msg
    this.tl_enter.from(introMessage1,{duration: 1.5,delay:0,opacity:0,y: 20})
    this.tl_enter.from(introMessage2,{duration: 1.5,delay:0.5,opacity:0,y: 20})
    this.tl_enter.from(introMessage3,{duration: 1.5,delay:0.5,opacity:0,y: 20})
    this.tl_enter.to(introMessages,{duration: 1.5,delay:1,opacity:0},"endIntro")
    
    // light
    //this.tl_enter.add(this.tl_light.play())
    this.tl_light.to(meditateImage4,{duration: 6,delay:0.5,opacity:1})

    this.tl_light_loop.to(meditateImage4,{duration: 4,opacity:0.2})
    this.tl_light_loop.to(backgroundBackdrop,{duration: 4,opacity:0.1},'-=4')
    this.tl_light_loop.to(meditateImage4,{duration: 4,opacity:1,delay:1})
    this.tl_light_loop.to(backgroundBackdrop,{duration: 4,opacity:0},'-=4')

    // meditate
    this.tl_enter.to(meditateImage2,{duration: 1.5,delay:1,opacity:0},"-=1.5")
    this.tl_enter.to(meditateImage3,{duration: 2,opacity:1,onStart:()=>{
      this.$store.dispatch('app/setMusic',require('@/assets/audio/meditate.mp3'))
    },onComplete:()=>{
      this.tl_light.play()
    }})

    this.tl_enter.from(meditateMessage1,{duration: 2,delay:1,opacity:0,y: 20,onStart:()=>{
      let speakAudio = this.$refs.speakAudio
      speakAudio.volume = 1
      speakAudio.play()
    }},'-=1')
    this.tl_enter.from(meditateMessage2,{duration: 2,delay:2,opacity:0,y: 20})
    this.tl_enter.from(meditateMessage3,{duration: 2,delay:1.5,opacity:0,y: 20})
    this.tl_enter.from(meditateMessage4,{duration: 2,delay:2,opacity:0,y: 20})
    this.tl_enter.from(meditateMessage5,{duration: 2,delay:2.5,opacity:0,y: 20,onComplete:()=>{
      this.tl_light_loop.play()
    }})    

    this.tl_enter.from(nextBtn,{duration: 1.5,delay:10,opacity:0,y: 20},"endScene")
  },
  methods: {
    next() {
      this.$router.replace('/prepare')
    },
    seek() {
      //this.tl_enter.seek(5)
      this.tl_enter.seek(this.tl_enter.nextLabel())
    }
  },
  beforeUnmount() {
    this.tl_enter.pause()
  },
  computed: {
    tl_enter() {
      return gsap.timeline();
    },
    tl_light() {
      return gsap.timeline({paused:true});
    },
    tl_light_loop() {
      return gsap.timeline({paused:true,repeat: -1,repeatDelay:1});
    }
  }
}
</script>

<style lang="scss" scoped>
#meditate-backdrop {
  background: #222;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}
#meditate-frame {
  width: 100%;
  height: 100%;

  background: no-repeat url('~@/assets/images/white-paper.jpg') top center;
  background-size: cover;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
  line-height: 1.7em;

  @media only screen and (max-width: 500px) {
    font-size: 0.93em;
  }
  #meditate-image {
    width: 500px;
    height: 520px;
    position: relative;
    img {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    @media only screen and (max-width: 500px) {
      width: 90vw;
      height: 95vw;
      padding: 0;
      margin: 0 auto;
    }
  }
  #meditate-image-1 {
    opacity: 0;
  }
  #meditate-image-2 {
    opacity: 0;
  }
  #meditate-image-3 {
    opacity: 0;
  }
  #meditate-image-4 {
    opacity: 0;
  }

  .button-text {
    margin: 40px 0 0 0;
  }

  #intro-messages {
    margin: 30px 0 0 0;
    span {
      display: inline-block;
      position: relative;
    }
  }

  #meditate-messages {
    margin: -1.5em 0 0 0;
    span {
      display: inline-block;
      position: relative;
    }
  }
}
</style>

