import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSocialSharing from 'vue-social-sharing'


createApp(App).use(store).use(router).use(VueSocialSharing).mount('#app')

/*
history.pushState(null, null, location.href);
window.onpopstate = function () {
    history.go(1);
};
*/
