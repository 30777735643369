<template>
  <div id="home-text">
    <div class="home-logo"><img :src="require('@/assets/images/logos/artimin.png')" width="90" /></div>
    <div id="mock-frame">
      <img :src="require('@/assets/images/q7-prepare/desk.png')"/>
    </div>
    
    <div class="home-intro-text">
      <p>
        <strong>Art Experience</strong><br/>
        <span>ก้าวเข้าสู่โลกของศิลปะดูแลใจ</span> <span>ซึ่งจะพาคุณออกเดินทางเพื่อสำรวจหัวใจ</span><br/>
        <span>ค้นหาคำตอบให้ชีวิตได้  Move on ต่อไป</span><br/>
        <span>ผ่านประสบการณ์ศิลปะง่ายๆ ไปกับเรา</span>
      </p>
      <button class="button-text" @click="next">เริ่มต้น &#xbb;</button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
    
  },
  mounted() {
    this.$store.dispatch('app/clearAnswer')
    this.$store.dispatch('app/stopMusic')
    let imageArray = [
      require('@/assets/images/white-paper.jpg'),
      require('@/assets/images/q1-intro/bird-fly-1.png'),
      require('@/assets/images/q1-intro/bird-fly-2.png'),
      require('@/assets/images/q1-intro/bird-fly-3.png'),
      require('@/assets/images/q1-intro/flying.png'),
      require('@/assets/images/q1-intro/outside-view.jpg'),
      require('@/assets/images/q2-3-greeting/bird-window.png'),
      require('@/assets/images/q2-3-greeting/bird-window-2.png'),
      require('@/assets/images/q2-3-greeting/bird-man.png'),
      require('@/assets/images/q5-6-meditate/l1-window.png'),
      require('@/assets/images/q5-6-meditate/l2-bird.png'),
      require('@/assets/images/q5-6-meditate/l3-bird-back.png'),
      require('@/assets/images/q5-6-meditate/l4-light.png'),
      require('@/assets/images/q7-prepare/desk.png'),
      require('@/assets/images/q8-choosetime/frame/paper.png'),
      require('@/assets/images/q8-choosetime/frame/window.png'),
      require('@/assets/images/q8-choosetime/frame/frame-morning.png'),
      require('@/assets/images/q8-choosetime/frame/frame-day.png'),
      require('@/assets/images/q8-choosetime/frame/frame-evening.png'),
      require('@/assets/images/q8-choosetime/frame/frame-night.png'),
      require('@/assets/images/q8-choosetime/btn-morning.png'),
      require('@/assets/images/q8-choosetime/btn-day.png'),
      require('@/assets/images/q8-choosetime/btn-evening.png'),
      require('@/assets/images/q8-choosetime/btn-night.png'),
      require('@/assets/images/q12-rest/man-rest.png'),
      require('@/assets/images/q12-rest/man-bird-frame.png'),
      require('@/assets/images/q12-rest/window-mask.png'),
      require('@/assets/images/canvas/canvas-day-cool.jpg'),
      require('@/assets/images/canvas/canvas-day-normal.jpg'),
      require('@/assets/images/canvas/canvas-day-warm.jpg'),
      require('@/assets/images/canvas/canvas-evening-cool.jpg'),
      require('@/assets/images/canvas/canvas-evening-normal.jpg'),
      require('@/assets/images/canvas/canvas-evening-warm.jpg'),
      require('@/assets/images/canvas/canvas-morning-cool.jpg'),
      require('@/assets/images/canvas/canvas-morning-normal.jpg'),
      require('@/assets/images/canvas/canvas-morning-warm.jpg'),
      require('@/assets/images/canvas/canvas-night-cool.jpg'),
      require('@/assets/images/canvas/canvas-night-normal.jpg'),
      require('@/assets/images/canvas/canvas-night-warm.jpg'),
      require('@/assets/images/canvas/ground-ground-day.png'),
      require('@/assets/images/canvas/ground-ground-evening.png'),
      require('@/assets/images/canvas/ground-ground-morning.png'),
      require('@/assets/images/canvas/ground-ground-night.png'),
      require('@/assets/images/canvas/ground-ground.png'),
      require('@/assets/images/canvas/ground-mountain-day.png'),
      require('@/assets/images/canvas/ground-mountain-evening.png'),
      require('@/assets/images/canvas/ground-mountain-morning.png'),
      require('@/assets/images/canvas/ground-mountain-night.png'),
      require('@/assets/images/canvas/ground-mountain.png'),
      require('@/assets/images/canvas/ground-river-day.png'),
      require('@/assets/images/canvas/ground-river-evening.png'),
      require('@/assets/images/canvas/ground-river-morning.png'),
      require('@/assets/images/canvas/ground-river-night.png'),
      require('@/assets/images/canvas/ground-river.png'),
      require('@/assets/images/canvas/ground-sea-cool.png'),
      require('@/assets/images/canvas/ground-sea-day.png'),
      require('@/assets/images/canvas/ground-sea-evening.png'),
      require('@/assets/images/canvas/ground-sea-morning.png'),
      require('@/assets/images/canvas/ground-sea-night.png'),
      require('@/assets/images/canvas/ground-sea.png'),
      require('@/assets/images/canvas/man-lay.png'),
      require('@/assets/images/canvas/man-relax.png'),
      require('@/assets/images/canvas/man-sit.png'),
      require('@/assets/images/canvas/man-stand.png'),
      require('@/assets/images/canvas/view-bird-animation-1.png'),
      require('@/assets/images/canvas/view-bird-animation-2.png'),
      require('@/assets/images/canvas/view-bird.png'),
      require('@/assets/images/canvas/view-cloud-animation-1.png'),
      require('@/assets/images/canvas/view-cloud.png'),
      require('@/assets/images/canvas/view-rain-animation-1.png'),
      require('@/assets/images/canvas/view-rain-animation-2.png'),
      require('@/assets/images/canvas/view-rain.png'),
      require('@/assets/images/canvas/view-star-animation-1.png'),
      require('@/assets/images/canvas/view-star-animation-2.png'),
      require('@/assets/images/canvas/view-star.png'),
      require('@/assets/images/canvas/view-tree-animation-1.png'),
      require('@/assets/images/canvas/view-tree.png')
    ]
    this.preload(imageArray,0)
  },
  methods: {
    next() {
      this.$router.replace('/intro')
    },
    preload(imageArray, index) {
      index = index || 0;
      if (imageArray && imageArray.length > index) {
        var img = new Image ();
        img.onload = () => {
          this.preload(imageArray, index + 1)
        }
        //console.log('loading image '+index)
        img.src = imageArray[index]
      } else {
        //console.log('loaded')
      }
    }
  }
}
</script>


<style lang="scss">
#home-text {
  background: no-repeat url('~@/assets/images/white-paper.jpg') top center;
  background-size: cover;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  top: 0;
  left: 0;
  font-size: 0.9em;
  line-height: 1.5em;

  strong {
    font-size: 1.2em;
    font-weight: strong;
    line-height: 2em;
  }
  span {
    white-space: nowrap;
  }
}
#mock-frame {
  max-width: 500px;
  margin: 0 auto 10px;
  img {
    width: 100%;
  }
}
.home-logo {
  padding: 10px;
}
</style>