<template>
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="in-out">
      <component :is="Component" />
    </transition>
  </router-view>
  <audio ref="audio" :src="background_music" preload loop mute></audio>
</template>

<style lang="scss">
</style>

<script>

//gsap.globalTimeline.timeScale(4)
export default {
  name: 'App',
  data() {
    return {
      background_music: null
    }
  },
  components: {
  },
  mounted() {
    this.$store.watch(() => this.$store.getters['app/getMusic'], val => { 
      if(val) {
        this.nextSound(val)
      } else {
        this.stopSound()
      }
    })
  },
  methods: {
    nextSound(audio) {
      if(this.background_music) {
        this.stopSound(audio)
      } else {
        this.background_music = audio
        this.playSound()
      }
    },
    playSound() {
      setTimeout(()=>{
        let backgroundAudio = this.$refs.audio
        backgroundAudio.volume = 1
        backgroundAudio.play()
      },150)
    },
    stopSound(audio) {
      let backgroundAudio = this.$refs.audio;
      let actualVolumeFadeOut = backgroundAudio.volume;
      clearInterval(fadeOutInterval);
      let fadeOutInterval = setInterval(() => {
        actualVolumeFadeOut = (parseFloat(actualVolumeFadeOut) - 0.1).toFixed( 1 );
        if (actualVolumeFadeOut >= 0) {
          backgroundAudio.volume = actualVolumeFadeOut;
        } else {
          backgroundAudio.pause();
          clearInterval(fadeOutInterval);
          if(audio) {
            this.background_music = audio
            this.playSound()
          } else {
            this.background_music = null
          }
        }
      }, 100);
    }
  }
}
</script>